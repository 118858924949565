class closePersonConfirmModalComponent extends Component {

    static name() {
        return "closePersonConfirmModalComponent";
    }

    static componentName() {
        return "closePersonConfirmModalComponent";
    }


    getComputed() {
        return  {
            currentCustomerPerson: function () {
                return this.$store.getters.getCurrentCustomerPerson;
            },

        };
    }

    getMethods() {
        return {
            DisablePerson: this.DisablePerson,
        };
    }

    DisablePerson() {
        this.$store.dispatch('ChangeActivationCustomerPerson', this.currentCustomerPerson.Code)
        this.$store.dispatch('showNotificacion',{title: '', message:  `Usuario ${this.currentCustomerPerson.WebUser} desactivado`,type: 'success'});
    }


    getTemplate() {
        return`<div class="modal fade item-detail-modal" id="ClosePersonModal" tabindex="-1" role="dialog">
                  <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title">{{tr("Confirm")}}</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <div class="modal-body" v-if="currentCustomerPerson">
                             <h5>{{tr("Are you sure to disable this Person?")}}</h5></br>
                             <h6>{{tr("Code")+': '+currentCustomerPerson.Code }}</h6>
                             <h6>{{tr("Name")+': '+currentCustomerPerson.Name+' '+currentCustomerPerson.LastName  }}</h6>
                          </div>
                          <div class="modal-footer">
                               <button class="btn secondary-btn" data-dismiss="modal" @click="DisablePerson()"><i class="far fa-thumbs-up"></i> {{tr('Yes')}}</button>
                               <button class="btn secondary-btn" data-dismiss="modal"><i class="fas fa-times-circle"></i> {{tr('No')}}</button>    
                          </div>                          
                      </div>
                  </div>
              </div>`;
    }
}

closePersonConfirmModalComponent.registerComponent();